<template>
  <div>
    <!-- Report Details -->
    <div class="vx-row">
      <div class="vx-col w-full mt-8">
        <vx-card>
          <div class="card-title">
            <h2>Available Reports</h2>
          </div>
          <div class="report-list">
            <ul>
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Visit Detail Report</h3>
                  <p>This report will show the companies visiting your site. Use this report to understand which companies have the most activity on your site and which pages they are viewing. The date range is set to one week by default.</p>
                  <div class="base-btn">
                    <vs-button                      
                      type="filled"
                      :to="'/view-report/uid/'+$route.params.id+'/VisitDetailReport'"
                      class="small-btn"
                      color="primary"
                    >Run Visit Detail Report</vs-button>
                  </div>
                </div>
              </li>
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Search Engine Report</h3>
                  <p>This report will show the search engines referring traffic to your site. Use this report to understand which search engines are producing the most visits to your site and which search terms are used. The date range is set to one week by default.</p>
                  <div class="base-btn">
                    <vs-button                      
                      type="filled"
                      class="small-btn"
                      color="primary"
                      :to="'/view-report/uid/'+$route.params.id+'/SearchEngineReport'"
                    >Run Search Engine Report</vs-button>
                  </div>
                </div>
              </li>
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Search Term Report</h3>
                  <p>This report will show the search term used to find your site. Use this report to understand which search terms are used by which search engines for finding your site. The date range is set to one week by default.</p>
                  <div class="base-btn">
                    <vs-button                      
                      type="filled"
                      :to="'/view-report/uid/'+$route.params.id+'/SearchTermReport'"
                      class="small-btn"
                      color="primary"
                    >Run Search Term Report</vs-button>
                  </div>
                </div>
              </li>
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Visit Raw Data Report</h3>
                  <p>This report will show raw visit data for your site. Use this report for further analysis of your site activity. The date range is set to one day by default. Only run this report for a short a timeframe. For a larger date range, please use the "Visit Raw Data Export"</p>
                  <div class="base-btn">
                    <vs-button                      
                      type="filled"
                      :to="'/view-report/uid/'+$route.params.id+'/VisitRawDataReport'"
                      class="small-btn"
                      color="primary"
                    >Run Visit Raw Data Report</vs-button>
                  </div>
                </div>
              </li>
              <li>
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Visit Raw Data Export</h3>
                  <p>This export will show raw visit data for your site in CSV format. Use this report for further analysis of your site activity. The date range is set to one day by default.</p>
                  <div class="base-btn">
                    <vs-button                      
                      type="filled"
                      :to="'/lead-report/uid/'+$route.params.id+'/LeadVisitRawDataReport'"
                      class="small-btn"
                      color="primary"
                    >Run Visit Raw Data Export</vs-button>
                  </div>
                </div>
              </li>
              <li v-if="ShowContactReport">
                <feather-icon icon="CheckIcon"></feather-icon>
                <div>
                  <h3>Visit Contact Export</h3>
                  <p>This export will show visitor contact data for your site in CSV format. Use this report for further analysis of your site activity. The date range is set to one day by default.</p>
                  <div class="base-btn">
                     <vs-button                      
                      type="filled"
                      :to="'/lead-report/uid/'+$route.params.id+'/LeadVisitContactExport'"
                      class="small-btn"
                      color="primary"
                    >Run Contact Export</vs-button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";

export default {
  components: {
    VxCard
  },
  data(){
    return{
      ShowContactReport:false,
    }
  },
  mounted() {
    let uid = this.$route.params.id;
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
     this.ShowContactReport = token[uid].ShowContactReport;
  },
  methods: {
    getContactData() {
      let fileName = "Visit_Raw_Data_Report.csv";
      this.axios
        .get("ws/MyReports/GetContactExportReport", {
          responseType: "blob"
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "text/csv"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
    }
  }
};
</script>
